import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgAnalog from '../svg/SvgAnalog';

export default class AquasenseOverView extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

  jsonGet(jsondata, key){
      try{
        return jsondata[key];
      }catch(error){
        //console.error(error);
        return {};
      }
    }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }


  render() {
    let aqs0690ComError = this.props.listComErrorSystem.includes("aqs-06-90");
    let aqs0691ComError = this.props.listComErrorSystem.includes("aqs-06-91");
    //let aqs0692ComError = this.props.listComErrorSystem.includes("aqs-06-92");
    //let aqs0693ComError = this.props.listComErrorSystem.includes("aqs-06-93");

    let aqs0690redCrossWhenComError = <></>;
    if(aqs0690ComError){
      aqs0690redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M120 310 L505 560 M120 560 L505 310"/>
    }
    let aqs0691redCrossWhenComError = <></>;
    if(aqs0691ComError){
      aqs0691redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M550 310 L935 560 M550 560 L935 310"/>
    }
    //let aqs0692redCrossWhenComError = <></>;
    //if(aqs0692ComError){
    //  aqs0692redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M980 310 L1365 560 M980 560 L1365 310"/>
    //}
    //let aqs0693redCrossWhenComError = <></>;
    //if(aqs0693ComError){
    //  aqs0693redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    //}

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    return(

      <div style={{height:"100%", width:"100%",backgroundColor:backgroundColor}}>

        <div style={{width:"100%", height:"100%"}}>
         <svg  style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
            <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >Overview</text>
            <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>

            <text x="312.5" y="215" fill={textColor} style={{fontSize:"34",alignmentBaseline:"right",textAnchor:"middle"}} >RAS 4</text>
            <text x="312.5" y="245" fill={textColor} style={{fontSize:"25",alignmentBaseline:"right",textAnchor:"middle"}} >AQS-06-90</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="120" y="260" width="385" height="490" ry="20"/>
            
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={185} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={310} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={435} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={185} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={310} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={435} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={185} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={310} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={435} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={310} y={730} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>

            {aqs0690redCrossWhenComError}

            {/* aqs-06-90 */}

            <text x="742.5" y="215" fill={textColor} style={{fontSize:"34",alignmentBaseline:"right",textAnchor:"middle"}} >RAS 3</text>
            <text x="742.5" y="245" fill={textColor} style={{fontSize:"25",alignmentBaseline:"right",textAnchor:"middle"}} >AQS-06-91</text>

            <rect fill="transparent" stroke="rgb(150,150,150)" x="550" y="260" width="385" height="490" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={615} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={740} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={865} y={400} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={615} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={740} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
             <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={865} y={510} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={615} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={740} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={865} y={620} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={740} y={730} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>

            {aqs0691redCrossWhenComError}

            {/* aqs-06-91 */}
            {/* Border for aqs-06-92 
            <text x="1172.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 2</text>

            <text x="1172.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-92</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="980" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1045} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1170} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1295} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1107.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1232.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1045} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1170} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1295} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            {aqs0692redCrossWhenComError}

            {/* aqs-06-92 */}

            {/* Border for AQS-06-93 
            <text x="1602.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >Well 1</text>

            <text x="1602.5" y="245" fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-93</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x="1410" y="260" width="385" height="350" ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={1475} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={1600} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={1725} y={410} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={1537.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={1662.5} y={500} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={1475} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={1600} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={1725} y={590} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            {aqs0693redCrossWhenComError}

            {/* AQS-06-93 */}
            
          </svg>
        </div>
 
      </div>
    )

    }

}
