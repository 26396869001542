import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faBell, faUser, faGear, faBookOpen, faDatabase, faClockRotateLeft, faChartLine, faLightbulb, faMoon} from '@fortawesome/free-solid-svg-icons'

import searasLogoBlue from './images/searas-logo-blue.png'
import searasLogoWhite from './images/searas-logo-white.png'

// Search and replace between AquasenseNH3/AquasenseNH3WellBoat and AquasenseWellBoat/Aquasense. depending on where system is used and what configuration is required.
import AquasenseNH3 from './aquasense/AquasenseNH3'
import AquasenseWellBoat from './aquasense/AquasenseWellBoat'
import Aquasense from './aquasense/Aquasense'
import AquasenseCustomTGP from './aquasense/AquasenseCustomTGP'
import AquasenseExhaust from './aquasense/AquasenseExhaust'
import AquasenseNH3WellBoat from './aquasense/AquasenseNH3WellBoat'
import AquasenseOverview from './aquasense/AquasenseOverview'
import Grafana from './aquasense/Grafana'
import SettingsMain from './settings/SettingsMain'
import DataVaultMain from './datavault/DataVaultMain'
import AlarmWindow from './alarms/AlarmWindow'
import AlarmLogWindow from './alarms/AlarmLogWindow'
import EventLogWindow from './datavault/EventLogWindow'

import {getServerUrl} from './ApplicationNetworking'

export default class Main extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        data:{},
        showPage:"",
        units:[],
        userName:"",
        userRole:"",
        sidebarCollapse:false,
        windowWidth:0,
        windowHeight:0,
        alarms:{},
        alarmsActiveUnacked:{},
        initData:false
      }
    }

  intervalPollData = 0;
  intervalPollAlarms = 0;


  componentDidMount() {
    this.updateDimensions()
    //window.addEventListener('resize', this.updateDimensions);
    this.getUserInfo();
    //this.getUnits();
    this.getData();
    this.intervalPollData = setInterval(this.getData, 3000);
    this.intervalPollAlarms = setInterval(this.getAlarms, 5000);
  }

  updateDimensions = () => {
    let width = window.innerWidth;
    let sidebarShouldCollapse = width < 700;
    this.setState({ windowWidth: width, windowHeight: window.innerHeight, sidebarCollapse:sidebarShouldCollapse});
  }

  componentWillUnmount() {
    clearInterval(this.intervalPollData);
    clearInterval(this.intervalPollAlarms);
  }

  pageIsActive = (pageName) => {
    if(pageName === this.state.showPage){
      return "active"
    }
    else{
      return ""
    }
  }

  updateAlarmUnacked = (alarmsJson) => {
    let alarms = {};
    try{
      alarms = JSON.parse(alarmsJson);
    }
    catch(error){
      // pass
    }
    let unAckedAlarms = {};
    unAckedAlarms["totalUnAckedAlarms"] = 0;
    Object.keys(alarms).forEach(systemName => {
        Object.keys(alarms[systemName]).forEach(alarmLabel => {
          Object.keys(alarms[systemName][alarmLabel]).forEach(alarmName => {
            let alarmActive = alarms[systemName][alarmLabel][alarmName].active;
            let alarmAcked = alarms[systemName][alarmLabel][alarmName].acked;
            if(!unAckedAlarms.hasOwnProperty(systemName.toLowerCase())){
                unAckedAlarms[systemName.toLowerCase()] = {}
            }
            if(!unAckedAlarms[systemName.toLowerCase()].hasOwnProperty("totalUnAckedAlarms")){
                unAckedAlarms[systemName.toLowerCase()]["totalUnAckedAlarms"] = 0
            }
            if(alarmActive && !alarmAcked){
              unAckedAlarms[systemName.toLowerCase()]["totalUnAckedAlarms"] = unAckedAlarms[systemName.toLowerCase()]["totalUnAckedAlarms"] + 1;
              unAckedAlarms["totalUnAckedAlarms"] = unAckedAlarms["totalUnAckedAlarms"] + 1;
            }

          })
        })
      });
    this.setState({alarmsActiveUnacked:unAckedAlarms});
  }

  isSystemCommunicationAlarm = (systemName) => {
    let comError = false;
    systemName = systemName.toUpperCase();
    let alarms = {};
    try{
      alarms = JSON.parse(this.state.alarms);
    }
    catch(error){
      // pass
    }
    if(alarms.hasOwnProperty(systemName)){
      // Check for alarm label 'COM_ERR'.
      if(alarms[systemName].hasOwnProperty("COM_ERR")){
        if(alarms[systemName]["COM_ERR"].hasOwnProperty("Communication error")){
          if(alarms[systemName]["COM_ERR"]["Communication error"].hasOwnProperty("active")){
            comError = alarms[systemName]["COM_ERR"]["Communication error"].active;
          }
        }
      }
    }
    return comError;
  }

  toggleSidebarCollapse = () => {
    this.setState({sidebarCollapse:!this.state.sidebarCollapse});
  }

  getUserInfo = () => {
    if(this.props.auth_token){
      this.setState({ isLoading:true, error:false });
      let url = getServerUrl() + "userinfo?auth_token=" + this.props.auth_token;
      fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, userName:data.username, userRole:data.role});
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
      }
  }

 getData = () => {
  if(this.props.auth_token){
    let mode = (!this.state.initData) ? "all" : "update";
    this.setState({ isLoading:true, error:false });
    let url = getServerUrl() + "data?auth_token=" + this.props.auth_token + "&mode=" + mode; 
    if(this.state.showPage.includes("aqs") || this.state.showPage.includes("nh3") || this.state.showPage.includes("Overview")){
      url = url + "&system_name_filter=" + this.state.showPage.toLowerCase();
    }
    fetch(url)
     .then((response) => {
       if(!response.ok) throw new Error(response);
       else return response.json();
     })
     .then((data) => {
        try{
          let inputData = JSON.parse(data);
          //console.log(inputData)
          let syncData = (this.state.data) ? this.state.data : {};
          if(inputData){
            Object.keys(inputData).forEach(topic => {
              syncData[topic] = inputData[topic]; 
            })
            this.setState({ isLoading:false, data:syncData, initData:true})
          }
        }
        catch(error){
          console.log(error);
        }
       
     })
     .catch((error) => {
       this.setState({ isLoading: false, error:true });
       this.props.resetAuthentication();
     });
    }
  }

 getAlarms = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false });
     let url = getServerUrl() + "alarms?auth_token=" + this.props.auth_token;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         this.setState({ isLoading:false, alarms:data});
         this.updateAlarmUnacked(data);
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  setShowPage = (pageName) => {
    this.setState({showPage:pageName});
    if(pageName.toLowerCase().includes("aqs") || pageName.toLowerCase().includes("nh3")){
      this.getData();
    }
  }

  doesSystemUnitHaveUnackAlarms = (unitName) => {
    let unAckedAlarmNumberForUnit = 0;
    if(this.state.alarmsActiveUnacked.hasOwnProperty(unitName.toLowerCase())){
      if(this.state.alarmsActiveUnacked[unitName.toLowerCase()].hasOwnProperty("totalUnAckedAlarms")){
        unAckedAlarmNumberForUnit = this.state.alarmsActiveUnacked[unitName.toLowerCase()].totalUnAckedAlarms;
      }
    }
    return unAckedAlarmNumberForUnit > 0;
  }

  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "rgb(242,242,242)" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)"
    let logo = this.props.colorTheme() === "light" ? searasLogoBlue : searasLogoWhite;
    let sidebarItemsBorderColor = this.props.colorTheme() === "light" ? "rgb(160,160,160)" : "rgb(200,200,200)";

    let mainContent = <div></div>;
    if(this.state.showPage.toLowerCase().includes("aqs")){
      mainContent = <AquasenseCustomTGP colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} userRole={this.state.userRole} unitName={this.state.showPage} userName={this.state.userName} data={this.state.data} systemCommunicationError={this.isSystemCommunicationAlarm(this.state.showPage)}/>
    }
    else if(this.state.showPage.toLowerCase().includes("nh3")){
      mainContent = <AquasenseNH3 colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} userRole={this.state.userRole} unitName={this.state.showPage} userName={this.state.userName} data={this.state.data} systemCommunicationError={this.isSystemCommunicationAlarm(this.state.showPage)}/>
    }
    else if(this.state.showPage === "settings"){
      mainContent = <SettingsMain colorTheme={this.props.colorTheme} userName={this.state.userName} userRole={this.state.userRole} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "datavault"){
      mainContent = <DataVaultMain colorTheme={this.props.colorTheme} userName={this.state.userName} userRole={this.state.userRole} data={this.state.data} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "alarms"){
      mainContent = <AlarmWindow colorTheme={this.props.colorTheme} alarms={this.state.alarms} userName={this.state.userName} userRole={this.state.userRole} auth_token={this.props.auth_token} updateAlarms={this.getAlarms}/>
    }
    else if(this.state.showPage === "alarmlog"){
      mainContent = <AlarmLogWindow colorTheme={this.props.colorTheme} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "eventlog"){
      mainContent = <EventLogWindow colorTheme={this.props.colorTheme} auth_token={this.props.auth_token}/>
    }
    else if(this.state.showPage === "grafana"){
        mainContent = <Grafana/>
    }
    else{
      let systemsCommunicationError = [];
      if(this.isSystemCommunicationAlarm("aqs-06-90")){
        systemsCommunicationError.push("aqs-06-90");
      } 
      if(this.isSystemCommunicationAlarm("aqs-06-91")){
        systemsCommunicationError.push("aqs-06-91");
      } 
      //mainContent = <AquasenseCustomTGP colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} userRole={this.state.userRole} unitName={"aqs-06-90"} userName={this.state.userName} data={this.state.data} systemCommunicationError={this.isSystemCommunicationAlarm(this.state.showPage)}/>
      mainContent = <AquasenseOverview colorTheme={this.props.colorTheme} units={this.state.units} data={this.state.data} listComErrorSystem={systemsCommunicationError}/>
    
    }

    let navAlarmSymbol = <></>;
    let alarmNumber = this.state.alarmsActiveUnacked.totalUnAckedAlarms;
    if(alarmNumber > 0){
      let alarmText = alarmNumber > 1 ? "alarms" : "alarm";
      navAlarmSymbol = <div style={{marginRight:"10px", padding:"10px"}} className="float-end">
                        <i style={{color:"rgb(213,0,0)"}}><FontAwesomeIcon size={"2x"} color="rgb(213,0,0)" icon={faBell} /> {alarmNumber} {alarmText}</i>
                      </div>;
    }




    return(
      <div>

        {/* Nav */}
        <div>
          <nav style={{backgroundColor:backgroundColor, borderBottom:"1px solid " + sidebarItemsBorderColor}}className="navbar">
            <div style={{marginLeft:"10px"}} className="border">
              <button className="navbar-toggler" type="button" onClick={() => this.toggleSidebarCollapse()}>
                <FontAwesomeIcon  style={{color:this.props.colorTheme() === "light" ? "black" : "white"}} icon={faBars} />
              </button>
            </div>

            <a href={() => false} className="navbar-brand mx-auto">
              <img src={logo} style={{maxWidth:"auto", maxHeight:"5vh"}} alt="Logo"/>
            </a>

            <div style={{marginRight:"10px", padding:"10px"}} className="float-end">
              <span>
              <FontAwesomeIcon style={{color:this.props.colorTheme() === "light" ? "black" : "white",marginRight:"10px"}} size="2x" icon={faUser} />
              <b style={{color:this.props.colorTheme() === "light" ? "black" : "white"}}>{this.state.userName}</b>
              </span>
            </div>

            {navAlarmSymbol}
            
          </nav>
        </div> {/* Nav */}


          {/* Sidebar */}
          <div style={{ backgroundColor:backgroundColor, borderRight:"1px solid " + sidebarItemsBorderColor, width: this.state.sidebarCollapse? '0px': '180px',height:"calc(100vh - 75px)"}} className="sidebar">

            {/*Removed OVERVIEW button on stand alone units. this must be uncommented on feks. wellboat systems*/}
            {/*<button style={{color:textColor, borderTop:0, borderRight:0, borderLeftColor:sidebarItemsBorderColor, borderBottomColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, fontWeight:this.state.showPage === "overview" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("overview")}>
            OVERVIEW
            </button>*/}


            <div className="text-center">
              <p style={{color:textColor}} className="my-1"> Aquasense </p>
            </div>
            
            <button style={{color:textColor, borderTop:0, borderRight:0, borderLeftColor:sidebarItemsBorderColor, borderBottomColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, fontWeight:this.state.showPage === "overview" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("overview")}>
            OVERVIEW
            </button>

            <div className="text-center">
              <p style={{color:textColor}} className="my-1"> RAS 4 </p>
            </div>

            <button style={{backgroundColor:this.doesSystemUnitHaveUnackAlarms("aqs-06-90") > 0 ? "rgb(213,0,0)" : "", color:textColor, borderRight:0, borderLeftColor:sidebarItemsBorderColor, borderBottomColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, fontWeight:this.state.showPage === "aqs-06-90" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("aqs-06-90")}>
            AQS-06-90
            </button>

            <div className="text-center">
              <p style={{color:textColor}} className="my-1"> RAS 3 </p>
            </div>

            <button style={{backgroundColor:this.doesSystemUnitHaveUnackAlarms("aqs-06-91") > 0 ? "rgb(213,0,0)" : "", color:textColor, borderRight:0, borderLeftColor:sidebarItemsBorderColor, borderBottomColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, fontWeight:this.state.showPage === "aqs-06-91" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("aqs-06-91")}>
            AQS-06-91
            </button>

            <div className="mt-4">
              <button style={{color:textColor, borderLeftColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, borderBottom:0, borderRight:0, fontWeight:this.state.showPage === "eventlog" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("eventlog")}>
              <FontAwesomeIcon icon={faBookOpen} /> EVENT LOG
              </button>
            </div>

            <button style={{color:textColor, borderLeftColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, borderBottom:0, borderRight:0, fontWeight:this.state.showPage === "alarmlog" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("alarmlog")}>
            <FontAwesomeIcon icon={faClockRotateLeft} /> ALARM LOG
            </button>

            <button style={{backgroundColor:this.state.alarmsActiveUnacked.totalUnAckedAlarms > 0 ? "rgb(213,0,0)" : "", borderBottom:0, borderRight:0, color:textColor, borderLeftColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, fontWeight:this.state.showPage === "alarms" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("alarms")}>
            <FontAwesomeIcon icon={faBell} /> ALARMS
            </button>

            <button style={{color:textColor, borderLeftColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, borderBottom:0, borderRight:0, fontWeight:this.state.showPage === "grafana" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("grafana")}>
            <FontAwesomeIcon icon={faChartLine} /> GRAFANA
            </button>

            <button style={{color:textColor, borderLeftColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, borderBottom:0, borderRight:0, fontWeight:this.state.showPage === "settings" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("settings")}>
            <FontAwesomeIcon icon={faGear} /> SETTINGS
            </button>

            <button style={{color:textColor, borderLeftColor:sidebarItemsBorderColor, borderBottomColor:sidebarItemsBorderColor, borderTopColor:sidebarItemsBorderColor, textAlign:"left", borderRadius:0, borderRight:0, fontWeight:this.state.showPage === "datavault" ? "bold" : "normal"}} className="btn btn-outline-secondary w-100 my-0" onClick={() => this.setShowPage("datavault")}>
            <FontAwesomeIcon icon={faDatabase} /> DATA VAULT
            </button>

            <div className="text-center my-2">
              <button className="btn btn-outline-secondary" onClick={() => this.props.setColorTheme((this.props.colorTheme() === "light")?"dark":"light")}>
                {this.props.colorTheme() === "light" ? <FontAwesomeIcon icon={faMoon} /> : <FontAwesomeIcon style={{color:"white"}} icon={faLightbulb} /> }
              </button>
            </div>

            <div style={{marginBottom:"100px"}} className="text-center">
              <button style={{color:textColor}} className="btn btn-outline-secondary" onClick={this.props.resetAuthentication}>Logout</button>
            </div>

          </div> {/* Sidebar */}

          <div style={{ width: this.state.sidebarCollapse? '100vw': 'calc(100vw - 180px)', marginLeft: this.state.sidebarCollapse? '0px': '180px',height:"calc(100vh - 75px)", position:"absolute", backgroundColor:backgroundColor}}>
                {mainContent}
          </div> {/* Content */}

      </div>
      )
  }

}
